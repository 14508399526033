.modalBackground {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modalContainer {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    width: 90%;
    max-height: 80%;
    overflow-y: auto;
    padding: 30px;
    position: relative;
  }
  
  .modalTitle {
    font-size: 2rem;
    color: #2c3e50;
    text-align: center;
    margin-bottom: 20px;
    border-bottom: 2px solid #2c3e50;
    padding-bottom: 10px;
  }
  
  .effectiveDate {
    font-size: 1rem;
    color: #7f8c8d;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .termsSection {
    margin-bottom: 30px;
    border-left: 4px solid #3498db;
    padding-left: 20px;
    background: #f7f9fc;
    border-radius: 10px;
    padding: 15px;
  }
  
  .termsSection h2 {
    font-size: 1.4rem;
    color: #2980b9;
    margin-bottom: 10px;
  }
  
  .termsSection p {
    line-height: 1.6;
    margin-bottom: 10px;
    color: #555;
  }
  
  .termsSection ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .termsSection ul li {
    margin-bottom: 10px;
    color: #555;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.8rem;
    color: #e74c3c;
    cursor: pointer;
  }
  
  .buttonContainer {
    text-align: center;
    margin-top: 20px;
  }
  
  .agreeButton {
    background-color: #1e88e5;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .agreeButton:hover {
    background-color: #1565c0;
  }
  
  /* Responsive Styles */
  @media (max-width: 900px) {
    .modalContainer {
      padding: 20px;
    }
  
    .modalTitle {
      font-size: 1.8rem;
    }
  
    .termsSection h2 {
      font-size: 1.2rem;
    }
  
    .termsSection p,
    .termsSection ul li {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 600px) {
    .modalContainer {
      padding: 15px;
    }
  
    .modalTitle {
      font-size: 1.6rem;
    }
  
    .termsSection h2 {
      font-size: 1rem;
    }
  
    .termsSection p,
    .termsSection ul li {
      font-size: 0.8rem;
    }
  }
  